
.cards {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
    padding: 1rem;
    transition: transform 0.2s;
  }
  
  .cards:hover {
    transform: translateY(-5px);
    transition: 0.4s;
    background-color: #4452557b;
  }
  
  .card-body {
    display: flex;
    align-items: center;
  }
  
  .card-icon {
    flex: 1;
    text-align: center;
    color: #17a2b8;
  }
  
  .card-info {
    flex: 2;
    text-align: right;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #343a40;
  }
  
  .card-count {
    font-size: 2rem;
    font-weight: 700;
    color: #007bff;
  }
  